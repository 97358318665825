export const locale = {
  lang: "da",
  data: {
    NAV: {
      APPLICATIONS: "Applikationer",
      DASHBOARD: "Overblik",
      NOTIFICATION: {
        TITLE: "Notifikationer",
        BADGE: "",
      },
      NEWGROUP: {
        FINDGROUP: "Find gruppe",
        ADDGROUP: "Opret ny gruppe",
      },
      MESSAGES: {
        TITLE: "Chat",
        BADGE: "",
      },
      CONTACTS: {
        TITLE: "Kontakter",
        BADGE: "",
      },
      OTHER: "Andet",
      "Quick-Access": "Hurtig adgang",
      "Back-Office": "Kontor",
      "User-Management": "brugerstyring",
      "Group-Management": "gruppestyring",
      "Coupon-Management": "Coupon Management",
      COMMUNITY: "Fællesskab",
      GROUP: "Grupper",
    },
    DASHBOARD: {
      WELCOME_BACK: "Velkommen tilbage",
      budget_summary: "Budgetoversigt",
      HOME: "Hjem",
    },
    TIMELINE: {
      write_something_here: "Skriv noget her...",
      mention_somebody: "Nævn nogen",
      add_photo: "Tilføj foto",
      add_location: "Tilføj placering",
      post: "Slå op",
    },
    DASHBOARD_TASK: {
      filter_tasks: "Filter Opgave",
      edit: "Redigere",
      delete: "Slet",
    },
    post: {
      thread_notification_on: "Trådmeddelelse Til",
      thread_notification_off: "Trådmeddelelse slået fra",
      post_comment: "sende",
      add_comment: "Tilføj en kommentar",
      update_comment: "Opdater kommentar",
      notice: "varsel",
      important: "vigtig",
      critical: "kritisk",
      show: "Vis",
      more_comments: "Flere kommentarer",
      show_more: "... Vis mere",
      show_less: " Vis mindre",
      pin_post: "Indlæg fastgjort",
      unpin_post: "Indlæg frigjort",
      location: "Beliggenhed",
      check_time_status: "redigeret",
    },
    TASKS: {
      file: "filer",
      comment: "Kommentarer",

      errors: {
        END_TIME_MUST_BE_GREATER_THAN_START_TIME:
          "Slut tid skal være større end start tid",
        FUTURE_TIME_CANNOT_BE_ADDED: "Opgaver kan ikke planlægges på forhånd.",
        CURRENT_TIME_CANNOT_BE_ADDED:
          "du kan ikke vælge det aktuelle tidspunkt for check-in/out-opdatering",
      },
    },
    NOTIFICATIONS: {
      notification: "Notifikationer",
      select_notification_to_view:
        "Vælg en notifikation for at læse indholdet!",
    },
    TOOLBAR: {
      new_version:
        "En ny opdatering er tilgængelig, venligst <a>klik her</a> for at se...",
      invite_to_group: "Inviter til gruppe",
      create_group: "Opret gruppe",
      create_task: "Opret opgaver",
      create_contact: "Opret Kontakt",
      create_coupon: "Create kupon",
      my_profile: "Min profil",
      inbox: "Indbakke",
      logout: "Log ud",
      no_shortcuts_yet: "Ingen genveje endnu!",
      click_to_add_remove_shortcut: "Klik for at tilføje/fjerne genveje.",
      remove: "Fjerne",
      cancel: "Afbryd",
      confirm: "Bekræfte",
      update: "Opdatering",
    },
    CHAT: {
      select_contact_to_chat: "Vælg en kontaktperson for at starte en chat!",
      search_or_start_chat: "Søg eller Start ny chat",
      start_conversation_by_typing:
        "Start en samtale ved at skrive din besked herunder.",
      select_contact_to_conversation:
        "Vælg en kontaktperson for at starte en samtale!",
      type_your_message: "Skriv din besked",
      accept_connection_request: "Acceptér anmodning om forbindelse",
      accept_connection: "Accepter forbindelse",
      reject_connection: "Afvis forbindelse",
      send_connection: "sende forbindelse",
      remove_connection: "Fjern forbindelse",
      delete_chat: "Slet chat",
      send_message: "Send besked",
      away: "Væk",
      do_not_disturb: "Forstyr ikke",
      mood: "Humør",
      other_users: "Andre brugere",
      contact_info: "Kontakt oplysninger",
      date_of_birth: "Fødselsdato",
      gender: "køn",
      websites_links: "Hjemmeside og links",
      mobile_phone: "Mobiltelefon",
      mobile_no: "Mobilnummer",
    },
    GROUPS: {
      welcome: "VELKOMMEN TIL GRUPPER",
      sub_welcome:
        "Grupper vil hjælpe dig gennem processen med at opbygge et fælleskab eller et team, " +
        " eller du kan bruge det til dit personlige sted at samle, organisere eller planlægge dine digital ting og sager. ",
      filter_group_by: "Filtrer grupper efter navn ...",
      all: "alle",
      valid_till:
        "Du bruger i øjeblikket en gratis prøveperiode for pro-grupper, gyldig til",
      your_groups: "Dine grupper",
      other_groups: "Andre grupper",
      go_to_group: "Gå til Gruppe",
      no_group_found: "Ingen gruppe fundet",
      no_group_found_text:
        "Hvis gruppen du søger ikke vises, kan det være fordi gruppens administrator har sat  gruppens indstillinger til Skjult. Du kan kontakte administratoren og bede vedkommende om at invitere dig, eller om at ændre gruppens indstillinger til Synlig.",
      search_for_group: "Søg efter en gruppe",
      patient_caller_slide: "opkald Dias",
      slide_show: "Dias",
      day: "Dag",
      hours: "Timer",
      minutes: "Minutter",
      seconds: "Sekunder",
      week: "uge",
      month: "måned",
      become_admin: "Bliv administrator",
      group_request: "Gruppe anmodning",
      group_member: "Gruppe medlemmer",
      members: "Medlemmer",
      leave_group: "Forlad gruppe",
      send_group_email: "Send gruppe Mail",
      setting: "Indstillinger",
      select_room: "Vælg Værelse",
      enter_name: "Indtast Navn",
      reset_form: "Nulstil formular",
      patient_name: "Patient Navn",
      delete_patient_caller: "Slet Patient Kalder",
      edit_patient_caller: "Redigere Patient Kalder",
      time: "Tid",
      caller: "Kalder",
      room: "Værelse",
      create_post: "Opret indlæg",
      timeline: "Tidslinje",
      task_management: "Status",
      planning: "Kalender",
      contact: "Kontakter",
      files: "Filer",
      membership: "Medlemskab",
      only_administrator_can_approve: "Administratorer",
      all_members_can_approve: "Alle medlemmer",
      visible_to_everyone: "Synlig",
      visible_to_everyone_tooltip:
        "findes i lister, men kun medlemmer har adgang",
      not_visible_to_everyone: "Ikke synlig",
      not_visible_to_everyone_tooltip: "kun inviterede brugere har adgang",
      group_visibility: "Gruppens synlighed",
      visibility: "Synlig i Søgning",
      hidden: "Skjult",
      slider: "Dias",
      task_control_ring: "Kalder",
      all_mem_can_approve: "Alle medlemmer kan godkende.",
      profile_form: "Gruppeprofil",
      group_profile: "Gruppeprofil",
      group_security: "Sikkerhed",
      group_plan_billing: "Plan & Betaling",
      group_Invoices: "Fakturaer",
      group_spendings: "Forbrug",
      group_settings: "Indstillinger",
      group_profile_desc: "Administrer gruppens udtryk og identitet",
      group_security_desc: "Administrer rettigheder og synlighed",
      group_plan_billing_desc:
        "Administrer gruppe og lager plan, samt betaling og fakturainformation",
      group_invoices_desc: "Administrer og se alle gruppens  fakturaer",
      group_spendings_desc: "Fa overblik over gruppens dataforbrug",
      group_setting_desc: "Administrer muligheder og begraensninger",
      group_profile_warning:
        "Følgende oplysninger vises offentligt, vær forsigtig",
      group_privacy: "Privatliv",
      group_backup: "gruppe backup",
      group_allow_sharing: "Tillad deling",
      group_visible_desc:
        " Gruppen vil vaere syn i sogning og enhver kan anmode om medlemskab",
      group_hidden_desc:
        "Gruppen vil være skjult. Kun inviterede brugere vil få adgang",
      group_membership_desc:
        "Kun gruppens administratorer kan godkende medlemmer",
      group_members_desc: "Alle i gruppen kan godkende nye\n" + "medlemmer",
      allow_large_file_desc:
        "Tillad brugerne at uploade store filer. Hvis dette tillades, er der ingen begrænsning. Vær opmærksom på, at dette kan øge gruppens lagerforbrug.",
      allow_sharing_desc:
        "Tillad brugerne at dele trådindhold og kontakter fra gruppen. Brugerspecifik info som brugerinfo og kommentarer deles ikke.",
      save: "Gemme",
      economy: "Økonomi",
      invoice_overview: "Fakturaoversigt",
      zip_code: "Postnummer",
      city: "By",
      graphic: "Grafisk",
      module_settings: "MODULINDSTILLINGER",
      slide_settings: "Dias indstillinger",
      caller_settings: "Kalder Indstillinger",
      planning_settings: "Planlægning",
      background_color: "Baggrundsfarve",
      add_more: "Tilføj mere",
      cannot_be_empty: "kan ikke være tom.",
      schedule: "kan ikke være tom.",
      group_approved_by: "Medlemskab godkendes af",
      show_on_dashboard: "Vis på startside",
      create_new_group: "Opret ny gruppe",
      go_today: "Vis i dag",
      header: "Ouups!",
      not_accessible: "Linket er ugyldig",
      content:
        "Siden du forsøger at nå findes ikke, eller siden kan ikke tilgås. Dette kan skyldes sikkerhedsindstillingerne på siden eller gruppen.",
      my_docs: "MY DOCS",
      my_timeline: "Min Tidslinie",
      my_files: "Mine Filer",
      my_calendar: "Min Kalender",
      my_task: "Mine Opgaver",
      my_contact: "Mine Kontakter",
      my_planning: "Planlægning",
      my_time_tracker: "Time Tracker",
      click_to_download_qr_code: "Click to download QR code",
      qr_code: "QR kode",
      toggle_buttons: {
        overview: "Oversigt",
        working_hours: "Arbejdstimer",
      },
      EXPORT_CSV: {
        from_date: "Fra dato",
        to_date: "Til dato",
        export_as_csv: "Eksporter som CSV",
      },
      CHECKIN_CHECKOUT: {
        in: "IN",
        out: "UD",
        edited: "Redigeret",
        FORM: {
          date: "Dato",
          check_in: "Tjek ind",
          check_out: "Tjek ud",
          date_error: "Ugyldig dato indtastet.",
          check_in_future_error: "Indtjekning kan ikke ske i fremtiden.",
          check_out_future_error: "Udtjekning kan ikke ske i fremtiden.",
          check_in_over_error:
            "Indtjekningstidspunktet skal være før udtjekningstidspunktet.",
          check_in_same_now_error:
            "Indtjekning kan ikke opdateres til det aktuelle tidspunkt.",
          check_out_same_now_error:
            "Check-out kan ikke opdateres til det aktuelle tidspunkt.",
        },
      },
      SETTINGS: {
        LEFT_SIDEBAR: {
          MODULES: {
            title: "Moduler",
            description: "Indstillinger for de enkelte moduler",

            calling: "Kalder",
            dias: "Dias",
            planning: "Planlægning",
            time_tracker: "Time Tracker",

            TIME_TRACKER: {
              title: "Time Tracker",
              description:
                "Aktivér dette modul for at give dine medlemmer mulighed for at tjekke ind / ud.",

              control_options: "Control Options",
              see_registrations:
                "Gruppemedlemmer kan se hinandens rgistreringer.",
              same_day_checkout:
                "Check out samme dag (Automatisk Check out 23:59)",
              timestamp: "QR timestamp",
              Options: "Other Options",
              check_in: "Check-in - Kræver QR",
              check_out: "Check-Out - Kræver QR",
              show_checkin_check_out_as_post:
                "Vis tjek ind/ud som apslag pa tidslinien",
              timestamp_slides: "Vis QR kode på Dias.",
              timestamp_group: "Vis QR kode på Gruppeforsiden",
              timestamp_group_slide: "Vis QR på groupMainPage + på diasshow.",
              timestamp_qr_rotate:
                "Brug varierende QR (koden vil skifte flere gange dagligt i interval af få minutter)",
              GPS_location: "Brug GPS position",
              working_hours: "Arbejdstid (første Check in, sidste Check out)",
              working_hours_description:
                "Aktivér arbejdstiden efter, hvornår du arbejder osv.",

              monday: "Mandag",
              tuesday: "Tirsdag",
              wednesday: "Onsdag",
              thursday: "Torsdag",
              friday: "Fredag",
              saturday: "Lørdag",
              sunday: "Søndag",

              mon: "M",
              tue: "T",
              wed: "O",
              thu: "T",
              fri: "F",
              sat: "L",
              sun: "S",

              from: "Fra",
              to: "Til",

              ERRORS: {
                overlap: "Dette tidsrum overlapper med et andet.",
                invalid:
                  "Sluttidspunktet (til) skal være større end starttidspunktet (fra).",
                invalid_from:
                  "Indtast et gyldigt starttidspunkt (fra) fra rullemenuen.",
                invalid_to:
                  "Indtast et gyldigt sluttidspunkt (til) fra rullemenuen.",
                select_a_day:
                  "Vælg mindst 1 dag, ellers kan du deaktivere arbejdstid.",
              },
            },
          },
        },
      },

      GROUP: {
        TIME_TRACKER: {
          DATE: {
            DATE_INTERVAL: "Dato interval",

            THIS_YEAR: "Dette År",
            THIS_MONTH: "Dette Måned",
            THIS_WEEK: "Dette Uge",

            LAST_YEAR: "Sidste År",
            LAST_MONTH: "Sidste Måned",
            LAST_WEEK: "Sidste Uge",

            CUSTOM: "Tilpasset",

            SICK: "Syg",
            ON_LEAVE: "Ferie",
            OTHER: "Fri",
            CHECK_IN: "Check IN",
          },

          STAT_OVERVIEW: {
            no_data: "Ingen data at vise endnu!",
          },
        },
      },
    },
    SEARCH: {
      search: "Søg",
      go_to_group: "Gå til gruppe",
      cancel_membership: "Opsig medlemskab",
      request_membership: "Anmod om medlemskab",
      search_folders: "Søg i mapper",
      search_in_folders: "Søg mappe",
      search_files: "Søg i filer",
      no_search_folders: "Ingen mapper fundet",
      show_more: "Vis mere",
      no_results: "Ingen resultater fundet",
      groups: "Grupper",
      search_for_app_page: "Søg efter en app eller side",
      no_group_folder_selected: "Ingen gruppe eller mappe er valgt.",
      threads: "Tråde",
    },
    THREAD: {
      priority: "prioriteter",
      show_destination: "Vis bekræftelsesside inden oprettelse",
      save: "Gem",
      update: "Opdatér",
      close: "Tæt",
      description: "Beskrivelse",
      description_req: "Beskrivelse er påkrævet",
      attachments: "vedhæftede filer",
      add_attachments: "Tilføj en vedhæftet fil",
      where_to_create_thread: "Hvor skal man oprette tråden",
      where_to_update_thread: "Hvor opdateres tråden",
      your_thread_will_be_created: "Din tråd vil blive oprettet herinde ..",
      your_thread_will_be_updated: "Din tråd opdateres herinde ..",
      location_group: "Placering (gruppe)",
      change_group: "Skift gruppe",
      create_thread: "Opret tråd",
      update_thread: "Opdater tråd",
      dont_show_this_confirmation_page: "Vis ikke denne bekræftelsesside",
      title: "Titel",
      share: "Del",
      choose_group: "Vælg gruppe",
      fixed: "Faste",
      all_group: "Alle grupper",
      search_for_group: "Søg efter gruppe",
      check_in: "Tjek ind",
      check_out: "Tjek ud",
      checkInOut_timeError:
        "du kan ikke vælge det aktuelle tidspunkt for opdatering af check-in/out",
      checkout_TimeRangeError:
        "Indtjekningstiden skal være mindre end udtjekningstiden",
    },
    SLIDE: {
      one: "2 x Lille indhold",
      sec_one: "2 x Lille ikoner",
      two: "1 x Stor indhold",
      sec_two: "1 x Lille ikoner",
      three: "1 x Lille indhold",
      sec_three: "1 x Lille ikoner",
      four: "1 x Lille indhold",
      sec_four: "",
      five: "kun billede",
      sec_five: "(Kun baggrund)",
      six: "kun video",
      sec_six: "(Kun baggrund)",
      above: "Over",
      below: "Under",
      image: "Image",
      colors: "Farver",
      schedule: "Tidsplan",
      end: "Ende",
      slide_message: "Diasbesked er påkrævet",
      start_time: "Start tidspunkt",
      slide_start_message: "Slide Besked Starttid er påkrævet!",
      end_time: "Sluttid",
      slide_end_message: "Slide besked sluttidspunkt er påkrævet!",
      select_background: "Vælg baggrund",
      select_template_type: "Vælg en skabelon type.",
      select_template: "vælg skabelon",
      next: "Næste",
      fill_out_slide: "Udfyld dias detalje",
      background: "baggrund",
      text_background: "Tekst Baggrund",
      text_background_color: "Tekst baggrunds farve er påkrævet!",
      text_color: "Tekst farve",
      text_color_req: "Tekst farve er påkrævet!",
      text_size: "tekst størrelse",
      text_size_req: "Tekst størrelse er påkrævet!",
      choose_file: "Vælg fil",
      title: "Titel",
      slide_title_req_1: "dias 1 titel er påkrævet",
      slide_title_req_2: "dias 1 titel er påkrævet",
      content: "indhold",
      slide_content_req_1: "dias 1 Besked er påkrævet!",
      slide_content_req_2: "dias 2 Besked er påkrævet!",
      previous: "Tidligere",
      is_required: "er påkrævet!",
      slide_required: "Tidsplan er påkrævet!",
      date: "Dato",
      select_all: "Vælg alle",
      unselect_all: "Fjern alle markeringer",
      odd_week: "ulige uge",
      even_week: "endda uge",
      sunday: "Søndag",
      monday: "Mandag",
      tuesday: "Tirsdag",
      wednesday: "Onsdag",
      thursday: "Torsdag",
      friday: "Fredag",
      saturday: "lørdag",
      share_with_others: "Dele med andre (offentligt)",
      finish: "Afslut",
      font_size: "Font Size",
      date_font: "Dato skrifttype",
      request: "forespørgsel",
      only_visible_to_friends: "Kun synlig for venner.",
      additional: "Yderligere",
      basic: "grundlæggende",
    },
    TASK_MANAGEMENT: {
      today_only: "Kun i dag",
      today_forward: "I dag og fremad",
      today_past: "I dag og fortid",
      archive: "Arkiv",
      user: "Bruger",
      sub_user: "Under bruger",
      task_status: "Opgavestatus",
      summary: "Resumé",
      show_in_tasks: "Vis i opgaver",
      show_in_calender: "Vis i kalender",
      add_task: "tilføj en opgave",
      co_reporter: "Medreporter",
    },
    CONTACT: {
      name: "Navn",
      groupName: "Gruppenavn",
      invoiceName: "Fakturanavn (ELLER fulde navn)",
      invoice_name_error: "fakturanavn er påkrævet",
      number: "Nummer",
      contact: "kontakt",
      group: "Gruppe",
      groups: "Grupper",
      address: "Adresse",
      add: "Tilføje",
      create: "Opret",
      add_contact: "Tilfoj kontakt",
      create_new: "Opret ny",
      name_Validation: "må ikke være længere end 25 tegn",
      description_Validation:
        "Gruppebeskrivelse må ikke være længere end 70 tegn",
    },
    FOOTER: {
      changelog: "Ændringslog",
      documentation: "Dokumentation",
    },
    CATEGORIES: {
      move_up: "Flyt Op",
      move_down: "Flyt Ned",
      delete: "Slet",
      new_folder: "Ny mappe",
      folder_management: "Mappe administration",
      name_folder: "Navngiv ny mappe",
    },
    GROUP_INVITATION: {
      group_invitation_text:
        "Indtast mailadressen på den person, du vil invitere, skriv en personlig hilsen og " +
        " vælg nederst, hvilken gruppe du vil invitere til. " +
        " (Tip: Du kan invitere flere ad gangen ved at indtaste flere mailadresser).",
      message: "besked",
      send: "Send",
      invite_to: "Inviter til",
      text: "tekst",
      message_interval: "besked skrifttype",
      or: "Eller",
      personal_message: "Tilfoj personlig besked (optional)",
      invite: "Inviter",
    },
    GALLERY: {
      browser_not_supported: "Browser understøttes ikke",
      download: "Hent",
      delete: "Slette",
      application: "Applikation",
      size: "Størrelse",
      load_more: "Vis mere",
      image: "Billede",
      actions: "Handlinger",
    },
    SETTINGS: {
      activate_pro: "Aktiver PRO",
      storage_settings: "Lagerplads indstillinger",
      storage: "Lager",
      allow_large_files: "Tillad store filer",
      payment_profile: "Betalingsprofil PRO",
      number_already_exist: "Nummer findes allerede.",
      add_room: "Tilføj værelse",
      screen_width: "Skærmbredde",
      screen_height: "Skærmhøjde",
      slide_delay_settings: "Slides forsinkelse (sekunder)",
      animation: "Animationshastighed (sekunder)",
      background_music: "Baggrunds musik",
      date_time: "Dato tid",
      add_user: "Tilføj bruger",
      user_role: "Brugerrolle",
      yes: "Ja",
      no: "Nej",
      users: "Brugere",
      accept_to_delete_all: "Jeg godkender at slette alt indhold permanent",
      user: "bruger",
      keep_user_threads: "Beholder bruger tråde?",
      this_field: "Dette felt",
      filter_users: "Filtrer brugere",
      accept: "Acceptere",
      reject: "Afvise",
      active: "Aktiv",
      size: "størrelse",
      group_image: "Gruppebillede",
      phone: "telefon",
      winter: "vinter",
      summer: "sommer",
      end_date: "Slut dato",
      start_date: "Start dato",
      spring: "Forår",
      fall: "Efterår",
      show: "at vise",
      record_time: "Rekordtid",
      patient_section: "Kalder afdeling",
      room: "Værelse",
      section: "Afsnit",
      call_patient: "Kalder",
      video_call: "videoopkald",
      width: "bredde",
      patient_name: "Patientnavn",
      play: "Spil",
      time_interval: "Tids interval",
      create_folder: "Opret mappe",
      update_folder: "opdatering Folder",
      select_group: "Vælg gruppe ",
      modules: "MODULER",
      group_settings: "GRUPPEINDSTILLINGER",
      folder: "Mapper",
      domain: "Domæne",
      country: "Land",
      cvr_number: "CVR/SE nummer",
      on: "TIL",
      off: "FRA",
    },
    LOGIN: {
      login_placeholder:
        "Platformen der vil hjælpe dig gennem processen med at opbygge et fælleskab eller et team, " +
        " eller du kan bruge den til dit sted at samle, organisere eller planlægge dine digitale ting og sager.",
      reactivate_account: "Genaktiver konto",
      unlock: "låse op",
      password: "Kodeord",
      are_you_not: "er du ikke",
      inactivity:
        "På grund af inaktivitet er din session låst. Indtast din adgangskode for at fortsætte.",
      session_loked: "DIN SESSION ER LÅST",
      join_using:
        "Hav dine kontakter og vigtige OnlineDoc ting, lige ved hånden.",
      get_app: "Hent APP'en!",
      create_new_password: "OPRET NYT KODEORD",
      save_my_password: "GEM MIT KODEORD",
      reactivate_your_account: "GENAKTIVER DIN KONTO",
      resend_code_by_email: "Gensend kode på Email",
      resend_code_by_sms: "Gensend kode på SMS",
      read_more_btn: "Læs mere",
    },
    PROFILES: {
      personal_information: "Personlig information",
      change_password: "Skift kodeord",
      security: "Sikkerhed",
      inactivity_limit: "inaktivitetsgrænse",
      set_limit_before_logout: "Indstil minutter før logout",
      deactivate_account: "Deaktiver konto",
      delete_account: "Slet konto",
      add_number: "Tilføj nummer",
      mobile_matching: "Mobilnummer skal ikke matche andre mobilnumre.",
      mobile_empty: "Mobilnummer må ikke være tomt",
      invalid_phone: "Ugyldigt telefonnummer",
      number_greater: "Telefonnummeret må ikke være større end 10 cifre.",
      phone_less: "Telefonnummeret må ikke være mindre end 7 cifre.",
      number_already: "Nummer findes allerede.",
      address_empty: "Adressen må ikke være tom.",
      invalid: "Ugyldig",
      email_empty: "Email må ikke være tom",
      email_exist: "Email findes allerede",
      primary: "primær",
      change: "lave om",
      add_website: "tilføj websted",
      website_empty: "Websites må ikke være tomme",
      not_available: "Ikke tilgængelig",
      cancel_all: "annullere alt",
      basic_information: "Grundlæggende oplysninger",
      profession: "Erhverv",
      friends: "Venner",
      requests: "Anmodninger",
      joined_groups: "Tilmeldte grupper",
      current: "nuværende",
      new: "ny",
      confirm_new: "bekræfte nyt",
      must_match: "Skal matche",
      friend_requests: "Venne anmodning",
    },
    BROWSER_COMPATIBILITY: {
      browser_compatibility: "Browser Kompatibilitet",
      browser_message:
        "Det ser ud til, at du bruger den gamle browser, brug Modern Browser eller Google Chrome til at se de komplette funktioner.",
    },
    VERSION_UPDATED: {
      new_version:
        "En nyere version af OnlineDoc er tilgængelig. Klik på JA for at køre den nyeste version.",
      version: "Version opdatering",
    },
    SUSPENDED: {
      group_suspended: "Gruppen er suspenderet d",
      suspended_description:
        "Gruppen er suspenderet grundet manglende betaling",
      contact_admin:
        'Foretag venligst din betaling så hurtigt som muligt for at forhindre permanent datatab, eller <a href="mailto:{{support@onlinedoc.dk}}">kontakt support</a>, hvis du mener, at dette er en fejl.',
      contact_member:
        'For at forhindre permanent tab af data <a id="contact_member" (click)="openSettingsBy()">kontakt denne gruppes SuperAdmin </a> for yderligere information.',
      make_payment: "Betal nu",
      contact_super_admin: "kontakt superadmin",
    },
    STORAGE_SETTINGS: {
      storage_plan: "Lagerplan",
      current_plan_1: "Du kan ændre dit lager",
      current_plan_2: "Planlæg at opfylde dine lagerbehov",
      current_plan_3_a: "Vælg din",
      current_plan_3_b: "Gruppeplan",
      current_plan_3_c: "at møde",
      current_plan_4: "dine behov ift",
      current_plan_5: "funktioner og moduler",
      change_storage_plan: "Skift lagerplan",
      cancel_subscription: "Annuller abonnement",
      total_storage_space: "Samlet lagerplads",
      usage_by_type: "Efter type",
      type: "Type",
      used_storage: "brugt lager",
      available_storage: "tilgængelig lagerplads",
      videos: "videoer",
      images: "Billeder",
      changeImmediatley:
        "Hvis du nedgraderer midt i en betalt periode, vil du ikke få refunderet nogen betaling foretaget for den aktive periode.\n" +
        "Hvis du opgraderer midt i en periode, vil du blive opkrævet for hele perioden.",
      files: "Filer",
      size: "Størrelse",
      group_plans: "Gruppeplan",
      options: "muligheder",
      free: "Gratis",
      switch_to: "SKIFT TIL",
      active: "Aktiv",
      current: "nuværende",
      free_storage_included: "10 GB lagerplads inkluderet",
      timeline: "Tidslinje",
      file_explore: "Filudforsker",
      task: "Opgaver",
      calender: "Kalender",
      slideshow: "Slideshow",
      caller: "Kalder",
      custom_integrations: "Brugerdefinerede integrationer",
      third_part_software_ect: "(3. del software ect.)",
      get_started: "KOM IGANG",
      support_via_email_and_chat: "Support via e-mail og chat",
      contact_sales: "Kontakt Salg",
      basic: "BASIS",
      shift: "Skift",
      invoiceInformation: "Fakturainformation",
      private: "Privat",
      corporate: "Erhverv",
    },
    PAYMENT_INFO: {
      payment_profile: "Betalingsprofile",
      payment_profile_id: "Betalingsprofile-id",
      account_type: "Knototype",
      company_address: "Virksornhed ens navn og adresse",
      corporation: "Virksomhed",
      not_available: "Ikke tilgængelig",
      profile_id_info:
        "Betalingsprofilens id er det unikke nummer, som vi bruger til at identificere den juridiske enhed, der er ansvarlig for betalingen. Den juridiske enhed er den organisation eller enkeltperson, som er registreret med denne betalingskonto.",
      account_type_info:
        "Din betalingsprofil og dit land afgør, hvilke afgifter der indregnes i din opkrævning, og hvilke typer dokumenter du modtager.",
      group_info:
        "Dette er den juridisk registrerede information, som angives på fakturaer.",
      payment_method: "Betalingsmetode",
      discount_code: "Kuponkode",
      primary_discount_code: "Primær rabatkode",
      remove_discount_code: "Fjern rabatkode",
      no_discount_code_found: "Ingen rabatkode fundet",
      no_payment_method_found: "Intet kort fundet",
      add_payment_method: "Tilfoj en betalingsmetode",
      edit_payment_method: "Rediger betalingsmetoder",
      primary_payment_method: "Primær betalingsmetode",
      make_primary_payment_method: "Foretag primær betalingsmetode",
      remove_payment_method: "Fjern betalingsmetode",
      payment_summary_invoices: "Betalingsoversigt (fakturaer)",
      pdf_invoice: "PDF-faktura",
      csv_invoice: "CSV-faktura",
      no_invoices_found: "Ingen fakturaer fundet",
    },
    loading: "loading",
    All: "Alle",
    Requests: "Anmodninger",
    User: "Brugere",
    Profile: "Profil",
    "Slider Settings": "Dias Indstillinger",
    "Caller Settings": "Kalder Indstillinger ",
    "Group Backup": "Gruppe Backup",
    "Slider Settings > Date & Time": "Dias Indstillinger >  Dato & Tid",
    "Slider Settings > Season Schedule": "Dias Indstillinger >  Sæson Skema",
    "Caller Settings > Caller Section (Board)":
      "Kalder Indstillinger > Kalder Afsnittet (Skærm)",
    "Caller Settings > Rum Administration ":
      "Kalder Indstillinger > Rum Administration",
    "Caller Settings > Voice Call": "Kalder Indstillinger > Stemmekald ",
    "Caller Settings > Room Edit": "Kalder Indstillinger > Rum Administration",
    "Caller Settings > Room Section":
      "Kalder Indstillinger > Rum Afsnittet (Skærm)",
    "Caller Settings > Date Time": "Kalder Indstillinger > Dato & Tid",
    "Select Group": "Vælg Gruppe",
    "Caller Settings > Scroll Message": "Kalder Indstillinger > Rulle Besked",
    "Caller Settings > Caller": "Kalder Indstillinger > Kalder",
    IMPORTANT: "VIGTIG",
    CRITICAL: "KRITISK",
    NOTICE: "VARSEL",
    NORMAL: "NORMAL",
    "Delete ": "Slet ",
    "Are you sure you want to delete this group?":
      "Er du sikker på, at du vil slette denne gruppe?",
    "Today and Past": "I dag og tilbage",
    "Today Only": " Kun i dag",
    "Today and Forward": "I dag og Frem",
    "hours ago": "timer siden",
    "CREATE AN ACCOUNT": "OPRET EN KONTO",
    "LOGIN TO YOUR ACCOUNT": "LOG PÅ DIN KONTO",
    "Full Name": "Fulde navn",
    Email: "Email",
    Phone: "Telefon",
    Password: "Kodeord",
    "Password (Confirm)": "Kodeord (Bekræft)",
    Accept: "Acceptér",
    "terms and conditions": "Vilkår og betingelser",
    "Already have an account?": "Har du allerede en konto?",
    Login: "Log på",
    Welcome: "Velkommen",
    // 'LOGIN': 'LOG PÅ',
    "Welcome to the ONLINE": "Velkommen til ONLINE",
    "Remember Me": "Husk mig",
    "Forgot Password?": "Glemt kodeord?",
    Male: "Han",
    Female: "Kvinde",
    "Havent received a code?":
      "Har du ikke modtaget en kode? (Husk evt. at tjekke din spamfolder)",
    "Gender is required!": "Køn er påkrævet!",
    OR: "eller",
    "RECOVER YOUR PASSWORD": "Gendan dit Kodeord",
    "Go back to login": "Gå tilbage til login",
    "SEND RESET LINK": "SEND LINK TIL NULSTILLING",
    "Confirm your email address!": "Bekræft din emailadresse!",
    "A reset password e-mail has been sent to":
      "En e-mail med nulstillet adgangskode er sendt til",
    'Check your inbox and click on the "Confirm my email" link to confirm your email address.':
      'Tjek din indbakke, og klik på linket "Bekræft min e-mail" for at bekræfte din e-mail-adresse.',
    'Check your inbox and click on the "Reset Your Password" link to reset your account password.':
      'Tjek din indbakke, og klik på linket "Nulstil din adgangskode" for at nulstille din adgangskode til din konto.',
    "Go back to login page": "Gå tilbage til login-siden",
    "A reset password e-mail has been sent.":
      "Der er sendt en e-mail med nulstillet adgangskode.",
    "Hey! Thank you for checking out our app.":
      "Hej! Tak fordi du tjekker vores app.",
    "Please validate your account details by insert code received in email/phone or click on link available in email.":
      "Bekræft venligst dine kontooplysninger ved at indsætte kode modtaget i e-mail / telefon, eller klik på linket, der er tilgængeligt i e-mail.",
    "Please insert confirmation code received from your email or phone:":
      "Indsæt bekræftelseskode modtaget fra din e-mail eller telefon:",
    Code: "Kode",
    "Code is required": "Kode er påkrævet",
    "Please insert complete code": "Indsæt fuldstændig kode",
    "invalid or expired code": "ugyldig eller udløbet kode",
    Verify: "Verificere",
    "If you would like to resend email please type your email below:":
      "Hvis du vil sende e-mail igen, skal du skrive din e-mail nedenfor:",
    "RE SEND": "SEND IGEN",
    "Your Account is already confirmed.": "Din konto er allerede bekræftet.",
    "A confirmation e - mail has been sent to":
      "En bekræftelses e - mail er sendt til",
    "You might need to check the spam folder.":
      "Du skal muligvis kontrollere spam -mappen.",
    "The code has been send to": "Koden er blevet sendt til",
    "Maximum numbers should be 8.": "Det maksimale antal skal være 8.",
    "Minimum numbers should be 8.": "Minimumstal skal være 8.",
    "Maximum inactivity limit is 1500.": "Maksimal inaktivitetsgrænse er 1500.",
    "Minimum inactivity limit is 5.": "Minimumstal inaktivitetsgrænse er 5.",
    "Threads are showing on Dashboard": "Tråde vises nu i Oversigten",
    "Threads are hidden on Dashboard": "Tråde vises ikke i Oversigten",
    Sunday: "Søndag",
    Monday: "Mandag",
    Tuesday: "Tirsdag",
    Wednesday: "Onsdag",
    Thursday: "Torsdag",
    Friday: "Fredag",
    Saturday: "Lørdag",

    // error messages

    "Phone is requied": "Telefon kræves",
    "Full Name is required": "Fuldt navn kræves",
    "Email is required": "E-mail er påkrævet",
    "Please enter a valid email address":
      "Indtast venligst en gyldig e-mailadresse",
    "Phone is required": "Telefon er påkrævet",
    "Phone is invalid": "Telefonen er ugyldig",
    "Password is required": "adgangskode er påkrævet",
    "Full Name is invalid": "Det fulde navn er ugyldigt",
    "Password confirmation is required":
      "Bekræftelse af adgangskode er påkrævet",
    "Email already exists": "Emailen eksisterer allerede",
    "Passwords must match": "kodeordene skal være ens",
    "Do not have an account?": "Har du ikke en konto?",
    "Create an account": "Opret en konto",
    "Invalid username or password.": "Ugyldigt brugernavn eller kodeord.",
    "Password should be at least 6 characters long.":
      "Adgangskoden skal være mindst 6 tegn.",
    "No associated record with this email exists.":
      "Der findes ingen tilknyttet post med denne e-mail.",
    "Enter valid email address.": "Indtast gyldig e-mail-adresse.",

    "First Name": "Fornavn",
    "Last Name": "Efternavn",
    "Date of Birth": "Fødselsdato",
    "Male/Female": "Mand/Kvinde",
    "Contact Number": "Telefon nummer",
    "Confirm Password": "Bekræft kodeord",
    "Sign Up": "Tilmeld",
    "User Name": "Brugernavn",
    "Your Password": "Dit kodeord",
    "Keep me logged in": "Forbliv logget ind",
    "Sign In": "Login",
    "Forgot Password": "Glemt kodeord",
    "You can reset your Password here": "Du kan nulstille dit kodeord her",
    Send: "Send",
    "What type of user of you?": "Hvilken type bruger er du?",
    "Doctor/ Health Staff/ Citizen": "Læge / Sundhedsfaglig Personale / Borger",
    Update: "Opdater",
    "What are you up to?": "Hvad har du på sinde?",
    "Photos/Camera/Documents": "Billede / Kamera / Fil",
    "Search Filter": "Søg i filer",
    "Search in Files": "Søg i filer",
    "Search in Comments": "Søg i Kommentarer",
    "Search in Threads": "Søg i tråde",
    "Edit/ Delete": "Rediger / Slet",
    "Write a comment": "Skriv en kommentar",
    "Document List": "Dokumentliste",
    "Send Email": "Send Email",
    "Select Users": "Vælg Brugere",
    Subject: "Emne",
    "Write your message here": "Skriv din besked her",
    "Find Users": "Find brugere",
    "Search Users": "Vælg Brugere",
    "Selected Users": "Søg brugere",
    "Attach Files": "Vedhæft Filer",
    Invite: "Invitér",
    "Group Members": "Gruppemedlemmer",
    "Send Messasge": "Send besked",
    "Delete Member": "Slet medlem",
    Settings: "Indstillinger",
    Save: "Gem",
    "Write your group description here": "Beskriv din gruppe her",
    Privacy: "Privatliv",
    Public: "Community",
    Hidden: "Skjult",
    "Folder (Categories)": "Folder (Kategori)",
    "Category Name": "Kategori",
    "Add Category": "Tilføj kategori",
    "Delete Group": "Slet gruppe",
    "Send Invitation": "Send invitation",
    "Invite to": "Invitér  til",
    "No user is registered with this email. Do you want to send invite to this email?":
      "Ingen bruger er oprettet med denne Email. Vil du sende en invitation til denne Email?",
    "No/ Yes": "Nej / Ja",
    Chat: "Chat",
    Connections: "Forbindelser",
    Other: "Andet",
    "Day/Months/Year": "Dag / Måned / År",
    "Few seconds/minutes/hours ago": "Få sekunder / minutter / timer siden",
    "Accept Request/ Cancel": "Accepter forespørgsel  / Afbryd",
    "Create Thread": "Opret Tråden",
    "Thread Title": "Titel",
    "Thread description": "Hvad har du på sinde?",
    "Add Users": "Tilføj brugere",
    "Explore Users/Groups": "Udforsk Bruger / Grupper",
    "Awaiting Approval": "Afventer godkendelse",
    "Go to Group": "Gå til gruppe",
    "Create Group": "Opret Gruppe",
    "Group Title": "Gruppens navn",
    "Next/Done": "Næste / Færdig",
    "Select Categories": "Vælg Kategorier",
    "Create Categories": "Opret Kategorier",
    "Can be managed and created late, user settings Categories are used in forums and file management, for the indvidual group or clinic.":
      "Kategorier bruges i Forum og Filhån i den enkelte Gruppe eller Klinik. Kan oprettes og administreres senere fra indstillinger.",
    "Logout/ Settings/ Invite": "Log ud / Indstillinger / Invitér",
    "Contact Information": "Kontaktinfo",
    "Basic Information": "Basisinfo",
    "Website and Links": "Websted og links",
    Mobile: "Mobil",
    Address: "Adresse",
    "My Group History": "Mine Grupper",
    "Thread History": "Mine tråde",
    "Read More": "Læs mere",
    "Account Settings": "Profil indstillinger",
    "Change Password": "Ændre kodeord",
    "New Password": "Nyt kodeord",
    "Update Password": "Opdater kodeord",
    "Inactivity Settings (Set Minutes before logout)":
      "Instillinger for inaktivitet (set minutter før der logges automatisk ud)",
    Language: "Sprog",
    "Delete Account": "Slet Konto",
    "Deactivate Account": "Deaktiver Konto",
    "Delete/ Disable": "Slet / Deaktiver",
    "Warning You are about to delete group and  all its content. When deleting this,  All data in group will be deleted and can NOT be restored!":
      "Advarsel. Du er ved at slette gruppen og alt dens indhold. Hvis du sletter gruppen og alt dens indhold, vil det IKKE kunne genskabes!",
    "I Confirm that I understand that all data will be deleted with NO  option to restore.":
      "Jeg bekræfter, at jeg forstår, at alle data vil blive slettet uden mulighed for at gendanne.",
    "What You want to Delete Remove and Delete all the files  from this user. Remove and Keep the files from this user.":
      "Hvad vil du slette? Slet bruger og SLET brugeres filer. Slet bruger og BEHOLD brugerens filer.",
    "Cancel/Okay": "Annuller / Ok",
    "Please Enter your Password": "Indtast dit kodeord",
    "User deleted Successfully": "Brugeren er slettet",
    "Thread created Successfully": "tråden er slettet",
    "Group created Successfully": "Gruppen er oprettet",
    "Message sent Successfully": "Beskeden er sendt",
    "Message deleted Successfully": "Beskeden er slettet",
    "Thread deleted Successfully": "tråden er slettet",
    "Thread updated Successfully": "tråden er opdateret",
    "Comment sent Successfully": "Kommentaren er oprettet",
    "Comment deleted": "Kommentaren er slettet",
    "Comment updated": "Kommentaren er opdateret",
    "Invitation sent Successfully": "Invitationen er sendt",
    "Friend request sent Successfully": "Anmodning om forbindelse er sendt",
    "Request canceled": "Anmodning annulleret",
    "Membership request sent": "Anmodning om medlemskab er sendt",
    "Membership request canceled": "Anmodning om medlemskab er annulleret",
    "Group mail sent Successfully": "Gruppermail er sendt",
    "Invalid username or password": "Ukendt brugernavn eller kodeord",
    "Please check your email for Account Confirmation ":
      "En bekræftelsesmail er sendt til din Email",
    "Additional Information": "Supplerende Information",
    Gender: "Køn",
    "Long Press for Delete Link": "Langt tryk for at slette Link",
    "Please enter valid URL": "Benyt venligst valid URL",
    "User can add furthter description about himself":
      "Tilføj supplerende information om dig selv her",
    "Please Enter your Email and Password": "Indtast din Email og Kodeord",
    "Please fill all the fields": "Udfyld venligst alle felter",
    "Password does not match": "Kodeord er ikke ens",
    "You are not connected with internet":
      "Du har ikke forbindelse til Internettet",
    "User deactivated Successfully": "Profilen er deaktiveret",
    "This user is deactivated, click here here to send activation mail":
      "Denne bruger er deaktiveret, klik her for at sende aktiveringsmail",
    "Who can add files": "Hvem kan tilføje Filer?",
    "All members can/ Only I can": "Alle / Kun mig",
    "Recipients of thread": "Læsere",
    "Who can approve new members": "Hvem kan godkende medlemsanmodninger",
    "All members/ Only Admin": "Alle medlemmer / Kun Administratore",
    "Who can send group email": "Hvem kan sende gruppemails",
    "Folders (Categories)": "Folder (Kategorier)",
    "DELETE MEMBER": "SLET MEDLEM",
    "Group Info Update": "Opdater gruppeinfo",
    "Group Image Update": "Opdater Gruppebillede",
    "Group Request": "Anmod",
    "Update Message": "Opdater",
    "Delete Message": "Slet",
    "Group Email": "Gruppe Email",
    "Email Subject": "Emne",
    "Email Message": "Besked",
    "GROUP REQUEST": "Anmod",
    "Delete Thread": "Slet Tråden",
    "File Save Error": "Fejl!",
    "Saved!": "Gemt!",
    "File Download": "Gem fil",
    "Edit Thread": "Rediger Tråden",
    "Change Notification Setting": "Rediger notifikationer",
    "Updated successfully.": "Opdateret",
    "Group image uploaded successfully": "Gruppebillede gemt",
    "Group Title is Required": "Navngiv gruppen",
    "Request status has been updated.": "Status for anmodning er opdateret",
    "Message deleted successfully": "Beskeden er slettet",
    "Message updated successfully": "Beskeden er opdateret",
    "Email send successfully.": "Email er sendt",
    "Please provide Email Subject": "Tilføj emne",
    "Please provide email message": "Tilføj besked",
    "You have successfully unsubscribe.": "Dit medlemskab er slettet",
    "User Deleted successfully.": "Medlemmet er slettet",
    "Document deleted successfully": "Dokumentet er slettet",
    "Your requested file has been saved to your device": "Filen gemt!",
    "Successfully downloaded.": "Gemt OK",
    "Group deleted successfully.": "Gruppen er slettet",
    "notification update successfully": "Notifikation er opdateret",
    "Thread details are updated successfully": "Ændring er gennemført",
    "Do you want to create new thread?": "Vil du oprette en ny?",
    "... Show More": "... Vis mere",
    " Show Less": " Vis mindre",
    Comments: "Kommentarer",
    Files: "Filer",
    " User Groups": "Grupper",
    " Requested Groups": "Anmodede grupper",
    " Other Groups": "Andre grupper",
    "Other Users": "Andre brugere",
    "If you click on Sign Up, you accept our terms, readmore about how we collect, use and share your information, in our data policy and on how we use cookiesand similar technology, in our cookies policy. You may recieve SMS notification from us and may cancel them at any time.":
      "Hvis du klikker på Tilmeld dig, accepterer du vores vilkår, læs mere om, hvordan vi indsamler, bruger og deler dine oplysninger, i vores datapolitik og om, hvordan vi bruger cookies og lignende teknologi, i vores cookiepolitik. Du kan modtage SMS -besked fra os og kan annullere dem når som helst.",
    "What type of user are you?": "Hvilken type bruger er du?",
    "FORGOT PASSWORD?": "Glemt kodeord?",
    "You can reset your password here.": "Du kan nulstille dit kodeord her",
    "Do you want to send invitation to this e-mail":
      "Vil du sende invitationen til denne Email",
    Description: "Beskrivelse",
    "Description of the group here": "Beskrivelse af gruppen her",
    "Who can send group mail?": "Hvem kan sende Gruppemails?",
    "Select Recipients": "Vælg modtagere",
    "Group posts and members will be visible to all members":
      "Gruppen vil være synlig for alle og e.",
    Name: "Navn",
    "Group posts and members will be hidden to all members":
      "Gruppen vil være skjult for alle. Nye medlemmer skal inviteres.",
    "SEARCH FILTERS": "Søgefilter",
    "Group Name": "Gruppens navn",
    "ADD CATEGORIES": "Tilføj Foldere (Kategorier)",
    "SELECT RECIPIENTS": "Vælg modtagere",
    "Write a note...": "Skriv et notat",
    "No user is registered with this e-mail.":
      "Der er ingen bruger registreret med denne Email",
    Recipients: "Modtagere",
    Closed: "Lukket",
    "THREAD DETAILS": "Detaljer",
    PROFILE: "Profil",
    "Phone Number": "Telefon",
    Danish: "Dansk",
    English: "Engelsk",
    "My group and thread history": "Mine grupper",
    "Confirm New password": "Bekræft nyt kodeord",
    Profession: "Profession",
    "Add additional info here...": "Tilføj yderligere info her..",
    "minutes for auto logout": "Minutter før auto-logout",
    "My Thread History": "Aktivitetslog",
    "Web Links": "Links",
    "Inactivity Setting": "Indstilling af inaktivitet",
    "additional Info Here if any...": "Yderlig information her..",
    "Categories are used in forums and file management, for the individual group or clinic":
      "Kategorier kan bruges i den enkelte gruppe for at danne bedre overblik over indhold i gruppen.",
    "Group Description...": "Gruppebeskrivelse..",
    "ADD NEW CATEGORY": "Tilføj ny Kategori",
    "Can be manage and creates later, under settings":
      "Kan oprettes og redigeres senere fra Indstillinger",
    "create thread flow": "Tråden Indstillinger",
    "Who can Add files?": "Hvem kan tilføj Filer?",
    "Send also posting some e-mail notifications to members":
      "Send Tråden som Email til medlemmer",
    PERMISSIONS: "Tilladelser",
    "All Readers Can": "Alle",
    Doctors: "Læger",
    Priority: "Prioritet",
    "E-mail notification": "Email notifikation",
    "Write a Message": "Besked",
    Others: "Andet",
    CategoryFieldCell: "KategoriFeltCelle",
    "Name a Category": "Navngiv kategori",
    "ChatViewCell:": "",
    CommentText: "kommentar",
    CommentDetailViewCell: "",
    "Here goes the message": "Skriv besked her",
    "days ago": "dage",
    ConnectionViewCell: "",
    'Hello there! I"m using Inline Doc': "Hej! Jeg bruger onlinedoc",
    GroupViewCell: "",
    LinearRowViewCell: "",
    "Standard (Default)": "Standard",
    MemberInfoTableViewCell: "",
    "SEND MESSAGE": "Send besked",
    Next: "Næste",
    Back: "Tilbage",
    "Profile Update": "Opdater profil",
    "Account updated Successfully": "Konto er opdateret",
    "Group Creation": "Opret gruppe",
    "Group created successfully": "Gruppe oprettet",
    "Auto Log Out": "Auto Logout",
    "Due to inactivity you have been logged out from the current session":
      "Grundet inaktivitet er du blevet logget ud",
    "Group Description": "Gruppebeskrivelse",
    "Write a message": "Skriv en besked..",
    "write a comment": "Skriv en kommentar..",
    "Validation Failure": "Fejl ved validering",
    "Reset Password": "Nulstil kodeord",
    "are required": "kræves",
    "is required": "kræves",
    "is not a valid Email": "er ikke en gyldig Email",
    "Password and Confirm Password are not Same":
      "kodeord og bekræft kodeord er ikke",
    "User Sign Up Success": "Oprettelse af bruger er gennemført",
    "Password should be at least 6 characters long":
      "Kodeord skal være mindst 8 karakterer langt",
    "Confirm Password should be at least 6 characters long":
      "Bekræft kodeord skal være mindst 8 karakterer langt",
    "Valid Email Required": "Gyldig Email kræves",
    "To reset password Please provide a valid Email":
      "Indtast gyldig Email for at nulstille kodeordet",
    'Registered Email is required and can"t be empty.':
      "Registreret e -mail er påkrævet og må ikke være tom",
    'Password is required and can"t be empty':
      "Adgangskode er påkrævet og kan ikke være tom",
    "Friend Request": "Anmodning om forbindelse",
    "Membership request sent successfully": "Medlemsanmodning blev sendt",
    "Account confirmation is required. Please, check your email for the confirmation link":
      "Kontovalidering mangler. Se i din Email indbakke efter et link til validering",
    "If there is an account associated with #MAIL# you will receive an email with a link to reset your password":
      "Hvis der er en konto forbundet med [MAIL], modtager du en e -mail med et link til at nulstille din adgangskode",
    year: "År",
    month: "måned",
    day: "dag",
    hour: "time",
    minute: "minut",
    second: "sekund",
    months: "måneder",
    days: "dage",
    hours: "timer",
    minutes: "minutter",
    seconds: "sekunder",
    "a moment": "et øjeblik",
    ago: "siden",
    "Add additional info here": "Tilføj yderligere info her",
    "Selected Recipients": "Vælg modtagere",
    "Inactivity Time Update": "Indstillinger for inaktivitet",
    "Inactivity Time limit Updated successfully": "Indstillinger updateret!",
    "Contact information": "Kontaktinformtation",
    "Document Added Successfully": "Dokument tilføjet",
    "Yes/No": "Ja/Nej",
    "Do you want to create another?": "Vil du oprette en mere?",
    "Which Type of user are you?": "Hvilken brugertype er du?",
    "Delete Profile": "Slet profil",
    "Are you sure you want to delete?": "Er du sikker på at du vil slette?",
    "New Password and Confirm new password are required":
      "Ny adgangskode og bekræft ny adgangskode er påkrævet",
    "Current password is required": "Nuværende kodeord kræves",
    "Confirm new password is required": "Bekræft kodeord kræves",
    "Your Account is deactivated. Please confirm your account":
      "Din konto er inaktiv. Bekræft din konto",
    "Resend Confirmation": "Gensend bekræftelsesmail",
    "Your account is permanent deleted. Please contact admin":
      "Din konto er slettet. Kontakt venligst en Admin",
    "Account confirmation": "Kontobekræftelse",
    "This account is not confirmed": "Denne konto er ikke bekræft",
    "Confirmation link": "Bekræftelseslink",
    "You Are trying to delete #GROUP. This is not possible as long as other users are a member of this.":
      "Du forSøger at slette #GROUP. Dette er ikke muligt så længe der er andre brugere tilknyttet.",
    "If you delete a category / folder, all content will be deleted and can NOT be recreated. Alternatively, you can disable the category / folder by unchecking it in front of it in the list.":
      "Hvis du sletter en kategori/mappe, vil alt indhold blive slettet og kan IKKE genskabes. Alternativt kan du deaktivere kategorien/mappen ved at fjerne fluebenet foran den i listen.",
    "I agree that all content will be deleted":
      "Jeg godkender at alt indhold slettes",
    Contacts: "kontakter",
    "Search Contacts": "søg kontakter",
    Info: "Info",
    Type: "Type",
    Size: "størrelse",
    Location: "Beliggenhed",
    Edited: "redigeret",
    Created: "oprettet",
    Overview: "Oversigt",
    Week: "Uge",
    Sun: "Søn",
    Mon: "Man",
    Tue: "Tir",
    Wed: "Ons",
    Thu: "Tor",
    Fri: "Fre",
    Sat: "Lør",
    Total: "Total",
    Select: "Vælg",
    "Copy to next week": "Kopier til næste uge",
    "Copy to rest of the year": "Kopier til resten af året",
    "Do you want to mark": "Vil du markere",
    "as a": "som en",
    "Do you want to clear this status?": "Vil du slette denne status?",
    Sick: "Syg",
    Holiday: "Ferie",
    "On Leave": "på leave",
    "Day Off": "Fri",
    "is marked as": "er markeret som",
    "Change Status": "Skift status",
    "Clear Status": "Slet status",
    "Create Working Hours": "Opret arbejdstid",

    "You are about to create working hours for next year. How would you like to proceed?":
      "Du er ved at oprette arbejdstider til næste år. Hvordan vil du komme videre?",

    "Copy current year": "Kopier nuværende år",
    "Create with empty data": "Opret med tom data",
    "Gathering data. Please wait..": "Data hentet. Vent venligst..",
    Yes: "Ja",
    Cancel: "Annuller",
    Insert: "Insæt",
    "Currently checked in": "Er i øjeblikket tjekket ind",
    "Currently checked out": "I øjeblikket tjekket ud",
    Page: "Side",
    Done: "Færdig",
    "Paid (active)": "Paid (active)",
    "Paid (inactive)": "Paid (inactive)",
    "Discount (active)": "Discount (active)",
    "Discount (inactive)": "Discount (inactive)",
    Unpaid: "Unpaid",
    // error messages
  },
};
